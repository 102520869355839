import React, {useEffect, useState} from 'react';
import useScript from '../../util/useScript';
import env from '../../environment';
import {sendMessage, useMessage} from '@qempo.io/web-common/react';
import {
  PaymentProcessorReceiveEvent,
  PaymentProcessorSendEvent,
} from '../index';
import {Spinner} from '../../elements/Spinner';

export interface CulqiOptions {
  title?: string;
  currency: string;
  description: string;
  amount: number;
  lang?: string;
}

export interface CulqiCheckoutSuccess {
  token: string;
}

export interface CulqiCheckoutError {
  error: Record<string, string>;
}

export function Culqi(): JSX.Element {
  const [options, setOptions] = useState<CulqiOptions>();
  const [closed, setClosed] = useState(false);
  const status = useScript('https://checkout.culqi.com/js/v3');

  useMessage<CulqiOptions>(
    PaymentProcessorReceiveEvent.SETUP,
    env.targetOrigins,
    (event) => {
      setOptions({
        title: event.data.title,
        currency: event.data.currency,
        description: event.data.description,
        amount: event.data.amount,
        lang: event.data.lang,
      });
    }
  );

  useEffect(() => {
    if (!closed) {
      const timerId = setInterval(() => {
        const iframe = document.querySelector(
          `#culqi_checkout_frame`
        ) as HTMLIFrameElement;
        if (iframe && iframe.style.width === '0px' && !closed) {
          sendMessage(
            {event: PaymentProcessorSendEvent.CLOSE},
            env.targetOrigins
          );
          setClosed(true);
        }
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [closed]);

  useEffect(() => {
    if (status === 'ready') {
      if (window && (window as any).Culqi && options) {
        const desc = options.description.replace(/[^a-zA-Z0-9 ]/g, '');
        (window as any).Culqi.publicKey = env.culqi;
        (window as any).Culqi.settings({
          title: options.title || 'Qempo',
          currency: options.currency,
          description: desc.substr(0, 80),
          amount: options.amount,
        });
        (window as any).Culqi.options({
          lang: options.lang || 'ES',
        });

        (window as any).culqi = () => {
          const token = (window as any).Culqi.token;
          const error = (window as any).Culqi.error;

          if (token) {
            sendMessage<CulqiCheckoutSuccess>({
              event: PaymentProcessorSendEvent.CHECKOUT_SUCCESS,
              token,
            });
          } else {
            sendMessage<CulqiCheckoutError>({
              event: PaymentProcessorSendEvent.CHECKOUT_ERROR,
              error,
            });
          }
        };
        (window as any).Culqi.open();
        return () => {
          delete (window as any).culqi;
        };
      }
    }
  }, [status, options]);

  return <>{(status === 'loading' || !options) && <Spinner />}</>;
}
