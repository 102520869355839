import React from 'react';

import './style.css';

export function Spinner(): JSX.Element {
  return (
    <div className="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
