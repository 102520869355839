import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {getProcessorByName, PaymentProcessor, Processor} from './processor';

import './App.css';

type AppOptions = {
  processor: PaymentProcessor;
};

function App(): JSX.Element {
  const [appOptions, setAppOptions] = useState<AppOptions>();

  useEffect(() => {
    if (document) {
      const {processor} = queryString.parse(document.location.search);

      if (processor && typeof processor === 'string') {
        const p = getProcessorByName(processor.toUpperCase());
        if (p) {
          setAppOptions({
            processor: p,
          });
        }
      }
    }
  }, [setAppOptions]);

  return (
    <>{appOptions && <Processor paymentProcessor={appOptions.processor} />}</>
  );
}

export default App;
