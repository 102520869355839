import {Level, getLevelByName, LevelName} from '@qempo.io/web-common/logger';

export type AppEnvironment = 'local' | 'development' | 'production';

export interface Environment {
  env: AppEnvironment;
  logLevel: Level;
  version: string;
  targetOrigins: string[];
  culqi: string;
  niubizWeb: string;
  niubizWebJsLib: string;
}

const env: Environment = {
  env: (process.env.REACT_APP_ENV as AppEnvironment) || 'development',
  logLevel: getLevelByName(process.env.REACT_APP_LOG_LEVEL as LevelName),
  version: process.env.REACT_APP_VERSION || '',
  targetOrigins: ['*'],
  culqi: 'pk_test_qpbCb2QGJb5x7WNy',
  niubizWeb: '750000004',
  niubizWebJsLib:
    'https://static-content-qas.vnforapps.com/v2/js/checkout.js?qa=true',
};

if (process.env.REACT_APP_ENV === 'development') {
  env.targetOrigins = ['https://local.qempo.io/', 'https://dev.qempo.io/'];
} else if (process.env.REACT_APP_ENV === 'production') {
  env.logLevel = Level.INFO;
  env.targetOrigins = ['https://www.qempo.io/'];
  env.culqi = 'pk_live_ITuuwM030OKmN8aK';
  env.niubizWeb = '650178162';
  env.niubizWebJsLib = 'https://static-content.vnforapps.com/v2/js/checkout.js';

  // eslint-disable-next-line no-console
  console.log('env', env);
}

export default env;
