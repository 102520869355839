import React, {useEffect, useState} from 'react';
import useScript from '../../util/useScript';
import env from '../../environment';
import {Spinner} from '../../elements/Spinner';
import {sendMessage, useMessage} from '@qempo.io/web-common/react';
import {
  PaymentProcessorReceiveEvent,
  PaymentProcessorSendEvent,
} from '../index';

export interface NiubizWebOptions {
  sessiontoken: string;
  channel?: string;
  purchasenumber: string;
  merchantlogo: string;
  formbuttoncolor?: string;
  amount: string;
  expirationminutes?: number;
  timeouturl: string;
  action: string;
}

export interface NiubizWebCheckoutSuccess {
  token: string;
}

export interface NiubizWebCheckoutError {
  error: Record<string, string>;
}

export function NiubizWeb(): JSX.Element {
  const [options, setOptions] = useState<NiubizWebOptions>();
  const [closed, setClosed] = useState(false);
  const status = useScript(env.niubizWebJsLib);

  useMessage<NiubizWebOptions>(
    PaymentProcessorReceiveEvent.SETUP,
    env.targetOrigins,
    (event) => {
      setOptions({
        sessiontoken: event.data.sessiontoken,
        channel: event.data.channel,
        purchasenumber: event.data.purchasenumber,
        merchantlogo: event.data.merchantlogo,
        formbuttoncolor: event.data.formbuttoncolor,
        amount: event.data.amount,
        expirationminutes: event.data.expirationminutes,
        timeouturl: event.data.timeouturl,
        action: event.data.action,
      });
    }
  );

  useEffect(() => {
    if (!closed) {
      const timerId = setInterval(() => {
        const iframe = document.querySelector(
          `#visaNetJS`
        ) as HTMLIFrameElement;
        if (iframe && iframe.style.display === 'none' && !closed) {
          sendMessage(
            {event: PaymentProcessorSendEvent.CLOSE},
            env.targetOrigins
          );
          setClosed(true);
        }
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [closed]);

  useEffect(() => {
    if (status === 'ready') {
      if (window && (window as any).VisanetCheckout && options) {
        const config = {
          sessiontoken: options.sessiontoken,
          channel: options.channel || 'web',
          merchantid: env.niubizWeb,
          purchasenumber: options.purchasenumber,
          merchantlogo: options.merchantlogo,
          formbuttoncolor: options.formbuttoncolor || '#0186F7',
          amount: options.amount,
          expirationminutes: options.expirationminutes || 5,
          timeouturl: options.timeouturl,
          action: options.action,
        };
        (window as any).VisanetCheckout.configure(config);
        (window as any).VisanetCheckout.open();
      }
    }
  }, [status, options]);

  return <>{(status === 'loading' || !options) && <Spinner />}</>;
}
