import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {sendMessage} from '@qempo.io/web-common/react';
import {Culqi} from './culqi';
import {NiubizWeb} from './niubizWeb';
import env from '../environment';

export enum PaymentProcessor {
  CULQI = 'CULQI',
  NIUBIZ_WEB = 'NIUBIZ_WEB',
}

export enum PaymentProcessorSendEvent {
  CLOSE = 'CLOSE',
  READY_TO_SETUP = 'READY_TO_SETUP',
  CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS',
  CHECKOUT_ERROR = 'CHECKOUT_ERROR',
}

export enum PaymentProcessorReceiveEvent {
  SETUP = 'SETUP',
}

type Props = {
  paymentProcessor: PaymentProcessor;
};

export function getProcessorByName(name: string): PaymentProcessor | undefined {
  switch (name) {
    case 'CULQI':
      return PaymentProcessor.CULQI;
    case 'NIUBIZ_WEB':
      return PaymentProcessor.NIUBIZ_WEB;
  }
}

export function Processor(props: Props): JSX.Element {
  const {paymentProcessor} = props;

  useEffect(
    () =>
      sendMessage(
        {
          event: PaymentProcessorSendEvent.READY_TO_SETUP,
        },
        env.targetOrigins
      ),
    [sendMessage]
  );

  return (
    <>
      {paymentProcessor === PaymentProcessor.CULQI && <Culqi />}
      {paymentProcessor === PaymentProcessor.NIUBIZ_WEB && <NiubizWeb />}
    </>
  );
}

Processor.propTypes = {
  paymentProcessor: PropTypes.string.isRequired,
};
